import PagePathConstant from '@lib/constants/page-path.constant'
import LayoutConstant from '@lib/constants/layout.constant'
import SystemHelper from '@lib/helpers/system.helper'
import { useRouter } from 'next/router'
import {
  createTenantDomainAndPath,
  loginElastioDomainAndPath,
} from '@features/authentication/use-appInitialization'
import { useEffect } from 'react'
import GlobalLoginPage from '@pages/global'
import TenantCreationPage from '@pages/tenant-creation'

function HomePage() {
  const router = useRouter()
  const domain = window.location.hostname.toLowerCase()
  const shouldRenderLoginPage = loginElastioDomainAndPath.domain.test(domain)
  const shouldRenderCreateTenantPage =
    createTenantDomainAndPath.domain.test(domain)
  const shouldRedirectToDashboard =
    !shouldRenderLoginPage && !shouldRenderCreateTenantPage

  useEffect(() => {
    if (!shouldRedirectToDashboard) {
      return
    }

    router.push(PagePathConstant.DASHBOARD)
  }, [shouldRedirectToDashboard])

  if (shouldRenderLoginPage) {
    return <GlobalLoginPage />
  }

  if (shouldRenderCreateTenantPage) {
    return <TenantCreationPage />
  }

  return null
}

HomePage.pageMeta = {
  metaTitle: SystemHelper.getCurrentPageName('Dashboard'),
  metaDescription: 'The page for dashboard',
  metaKeywords: '',
  path: PagePathConstant.HOME,
  layout: LayoutConstant.Empty,
}

export default HomePage
